import React from "react"
import Hero from '../components/Hero';
import Layout from "../components/shared/layout"
import LayoutContent from "../components/shared/layoutContent"
// import Instagram from '../components/shared/Instagram';
import LogoBox from '../components/shared/LogoBox';
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import T from '../components/T';
import FollowFooter from '../components/shared/FollowFooter';

const AboutPage = ({ data }) => {
  const { childContentfulAboutPageMainCopyRichTextNode } = data.allContentfulAboutPage.edges[0].node
  const content = [childContentfulAboutPageMainCopyRichTextNode]
  return (
    <Layout>
      <SEO title="About us"  url={`/about`} />
      <Hero
        title="About Us"
        techStack=""
      />
      <LayoutContent>
      <div className="tile is-ancestor">

      <div className="tile is-parent">
        <div className="tile is-vertical instructions">
          {content.map((item, index) => {
            return (
              <T key={index} item={item} />
            )
          })}
        </div>
      </div>
      <div className="tile is-4 is-vertical is-parent">
      <div className="tile is-child box">
          <div className="recipe__image-container">
              <LogoBox/>
          </div>

        </div>
        <div className="tile is-child box">
          <div className="recipe__image-container">
            <div className="">
              {/* <Instagram/> */}
            </div>
          </div>

        </div>

      </div>
      </div>
      <FollowFooter
          link="https://www.instagram.com/foodslut"
          copy="Follow us on instagram" 
          buttonCopy="FOLLOW"
          
          />
      </LayoutContent>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
query{
    allContentfulAboutPage {
        edges {
          node {
            id
            title
            childContentfulAboutPageMainCopyRichTextNode {
              json
            }
          }
        }
  }
}`


