import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image';
import './styles/instagram.css'
const LogoBox = () => (
    <StaticQuery
        query={graphql`
        query{
            allContentfulAboutPage {
                edges {
                  node {
                    id
                    logo {
                        fluid(maxWidth: 100, maxHeight:100){
                          src
                        ...GatsbyContentfulFluid
                      }
                    }
                    }
                  }
                }
        }
    `}
        render={data => {
            return (
                <div className="columns is-multiline">
                    <div className="column logo-box">
                        <Img fluid={data.allContentfulAboutPage.edges[0].node.logo.fluid} imgStyle={{
                            objectFit: "contain",
                        }} />
                    </div>

                </div>
            )
        }}
    />
)


export default LogoBox

